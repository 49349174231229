import photo2 from "./img/team-members-photos/3.png";
import photo3 from "./img/team-members-photos/10.png";
import photo4 from "./img/team-members-photos/11.png";
import photo5 from "./img/team-members-photos/5.png";
import photo6 from "./img/team-members-photos/16.png";
import photo7 from "./img/team-members-photos/7.png";
import photo8 from "./img/team-members-photos/8.png";
import photo9 from "./img/team-members-photos/2.png";
import photo10 from "./img/team-members-photos/15.png";

export default [photo2, photo3, photo4, photo5, photo6, photo7, photo8, photo9, photo10];
