import ic1 from "./img/development-within-company/ic-1.svg";
import ic2 from "./img/development-within-company/ic-2.svg";
import ic3 from "./img/development-within-company/ic-3.svg";
import ic4 from "./img/development-within-company/ic-4.svg";

export default {
  title: "Развитие внутри компании",
  blocks: [
    {
      name: "Сложные задачи",
      description:
        "Мы доверяем даже начинающему специалисту интересные и трудные задачи, чтобы он мог ускорить свой рост и прокачать навыки. Если вы хотите проявить свой потенциал, мы не будем вас сдерживать, напротив, менторы помогут в этом. ",
      icon: ic1,
    },
    {
      name: "Образование за счет компании",
      description:
        "Мы готовы оплатить любые курсы или отправить сотрудника на конференцию за счет компании. Оплатим перелет и купим билет на мероприятие в Америке или Европе, если полученные знания и опыт будут действительно ценными.",
      icon: ic2,
    },
    {
      name: "Обмен знаниями",
      description:
        "Наши сотрудники — это увлеченные, компетентные и открытые к общению люди. Начинающий специалист сможет многому научиться у наших сотрудников, которые являются профессионалами в разработке ГИС.",
      icon: ic3,
    },
    {
      name: "Карьерный рост",
      description:
        "Если сотрудник прокачал навыки, ускорил и оптимизировал работу команды, мы обязательно это оценим. Успех сотрудников в компании зависит только от качества их работы и инициативы.",
      icon: ic4,
    },
  ],
};
